import React from "react"
import styled from "styled-components"
import Map from "../components/map/map"
import Layout from "../components/layout/layout"
import ContactImg from "../images/contact.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Button from "../components/Button/button"
import { Helmet } from "react-helmet"

const ContactSection = styled.section`
  background-color: var(--neutral-300);
`

const ContactContainer = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  padding-bottom: 40px;
  display: block;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 980px) {
    display: flex;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  h1 {
    color: var(--accent-turquoise-500);
  }
  @media only screen and (min-width: 980px) {
    width: 47%;
  }
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  justify-items: start;
  align-items: stretch;
`;

const ImgContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  align-self: center;
  img {
    max-width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 980px) {
    width: 47%;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: var(--accent-orange-500);
  margin: 10px 0;
`;

const FormSection = styled.section`
background-color: var(--accent-orange-600);
`;

const DetailHead = styled.p`
  font-weight: 600;
  margin: 5px 0;
`;

const DetailBody = styled.p`
  margin: 5px 0;
  a {
    color: var(--accent-turquoise-400);
  }
`;

const FormContainer = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  padding: 10px 0 30px 0;
  h1 {
    color: var(--neutral-100);
    text-align: center;
  }
  form {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 750px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 30px;
    }
  }
`;

const Message = styled.textarea`
    grid-column-start: 1;
    @media only screen and (min-width: 750px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
`;

const LastName = styled.input`
  grid-column-start: 1;
  @media only screen and (min-width: 750px) {
    grid-column-start: 2;
  }
`;

const EnquiryType = styled.div`
  background-color: var(--accent-orange-600);
  position: relative;
  grid-column-start: 1;
  @media only screen and (min-width: 750px) {
    grid-column-start: 2;
  }

  ::after {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    margin-top: 3px;
    border: 6px solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;
    border-color: var(--neutral-600) transparent transparent transparent;
    content: "";
    transform: translateY(-50%);
    pointer-events: none;
  }

  select {
    background-color: var(--neutral-100);
  }
`;

const Email =  styled.input`
grid-column-start: 1;
`;

const ButtonBox = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: end;
  width: 100%;
  @media only screen and (min-width: 750px) {
    grid-column-start: 2;
    width: fit-content;
  }
`;

export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Contact | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <ContactSection>
        <ContactContainer>
          <TextContainer>
            <h1>Contact us</h1>
            <p>Please contact us via the website, or alternatively by email or telephone with any inquiries or questions you may have. We look forward to hearing from you.</p>
            <DetailsContainer>
              <Icon icon={faMapMarkerAlt}/>
              <div>
                <DetailHead>Address</DetailHead>
                <DetailBody>Caplin Solar, Stackley House,<br />Great Glen, Leicester. LE8 9GP</DetailBody>
              </div>
            </DetailsContainer>
            <DetailsContainer>
              <Icon icon={faPhoneAlt}/>
              <div>
                <DetailHead>Phone</DetailHead>
                <DetailBody><a href="tel:+441162593591">+44 1162 593 591</a></DetailBody>
              </div>
            </DetailsContainer>
            <DetailsContainer>
              <Icon icon={faEnvelope}/>
              <div>
                <DetailHead>Email</DetailHead>
                <DetailBody><a href="mailto:info@caplinsolar.co.uk">info@caplinsolar.co.uk</a></DetailBody>
              </div>
            </DetailsContainer>
          </TextContainer>
          <ImgContainer>
            <img src={ContactImg} alt="Methods of contact illustration" width="783px" height="449px" />
          </ImgContainer>
        </ContactContainer>
      </ContactSection>
      <FormSection>
        <FormContainer>
          <h1>Get in touch</h1>
          <div className="underline center" style={{borderTop: "3px solid white"}}></div>
          <form method="post" action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact2">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact2" />
            <input id="firstName" name="first-name" type="text" placeholder="First Name" required />
            <LastName id="lastName" name="last-name" type="text" placeholder="Last Name" required />
            <Email id="email" name="email" type="email" placeholder="Email Address" required />
            <EnquiryType>
              <select id="subject" name="subject">
                <option value="subject">Subject</option>
                <option value="customer-enquiry">Customer enquiry</option>
                <option value="business-enquiry">Business enquiry</option>
                <option value="general-enquiry">General enquiry</option>
              </select>
            </EnquiryType>
            <Message id="message" name="message" placeholder="Message" required></Message>
            <ButtonBox>
              <Button isFullwidth id="submit" type="submit" value="Send message" backgroundColor="white" foregroundColor="hsl(15,87%,57%)">Send message</Button>
            </ButtonBox>
          </form>
        </FormContainer>
      </FormSection>
      <Map />
    </Layout>
  )
}
