import React from "react"
import {Helmet} from "react-helmet"
import Icon from "../../images/CS_icon.png"

if (typeof window !== 'undefined') {
    const location = {lat: 52.586731, lng: -1.022502}
    window.initMap = function() {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: location,
            zoom: 12,
        })

        const marker = new window.google.maps.Marker({position: location, map: map, icon: Icon})
        const infoWindow = new window.google.maps.InfoWindow({
            content: `<div style="padding: 0 20px"><h3 style="margin: 5px 0">Caplin Solar</h3><p>Stackley House<br>Stretton Road<br>Great Glen<br>Leicester<br>LE8 9GP</p></div>`
        })
        
        marker.addListener('click', function() {
            infoWindow.open(map, marker)
        })
    }           
}

export default function Map() {
    return (
        <section style={{padding: 0}}>
            <Helmet>
                <script defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAoxYsoWFCfoMotraucnRkLk6xPY5jTmIY&callback=initMap"></script>
            </Helmet>
            <div id="map" style={{ height: 500, width: '100%'}}></div>
        </section>
    )
}